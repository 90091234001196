.content {
    height: 50px;
}

.footer {
    padding-top: 11px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: flex-end;
}

.customBody {
    color: var(--adm-color-success);
}

.grid-demo-item-block {
    text-align: center;
    color: '#ffffff';
    height: 100%;
  }
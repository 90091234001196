@import url('https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Courgette&family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  font-family: 'prompt';
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  max-height: '100vh';
}

.login-form,
.signup-form {
  max-width: 350px;
  margin: auto;
}

.ant-card{
  margin-bottom: 2rem;
  width: 100%;
  border-radius: 1rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 2px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / .1), 0 2px 4px -2px rgb(0 0 0 / .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
}

.ant-card-meta-title {
  font-size: 9px;
}

.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* CSS for hiding the search component on smaller screens */
@media (max-width: 768px) {
  .search-component {
    display: none;
  }
}

.custom-menu-background {
  background-color: #007bff; /* Replace with your desired background color */
  color: '#ffffff'; 
}

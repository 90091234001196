.rtl {
  direction: rtl;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  .invoice-information {
    float: left !important;
    text-align: left !important;
  }
  .invoice-head {
    .client-info {
      text-align: right !important;
    }
    .client-data {
      text-align: left !important;
    }
  }
  .invoice-body {
    .table {
      thead {
        tr {
          th {
            text-align: right !important;
          }
        }
      }
      tbody {
        tr {
          td {
            text-align: right !important;
            &:nth-child(2) {
              text-align: left !important;
            }
          }
        }
      }
    }
    .flex-table {
      .flex-column {
        .table-subtotal {
          tbody {
            tr {
              td {
                text-align: right !important;
                &:nth-child(2) {
                  text-align: left !important;
                }
              }
            }
          }
        }
      }
    }
    .invoice-total-amount {
      p {
        text-align: left !important;
      }
    }
  }
}

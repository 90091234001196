body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
}
.container {
    width: 90%;
    margin: 0 auto;
    padding: 20px 0;
}
.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
}
.product-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
}
.product-item:hover {
    transform: scale(1.05);
}
.product-image img {
    width: 100%;
    height: auto;
}
.product-info {
    padding: 15px;
    text-align: center;
}
.product-name {
    font-size: 16px;
    margin: 10px 0;
    color: #333;
}
.product-price {
    font-size: 18px;
    color: #e91e63;
}
.add-to-cart {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #e91e63;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.2s;
}
.add-to-cart:hover {
    background-color: #d81b60;
}